// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-chess-js": () => import("./../../../src/pages/chess.js" /* webpackChunkName: "component---src-pages-chess-js" */),
  "component---src-pages-coding-archived-js": () => import("./../../../src/pages/codingArchived.js" /* webpackChunkName: "component---src-pages-coding-archived-js" */),
  "component---src-pages-coding-js": () => import("./../../../src/pages/coding.js" /* webpackChunkName: "component---src-pages-coding-js" */),
  "component---src-pages-eighth-js": () => import("./../../../src/pages/eighth.js" /* webpackChunkName: "component---src-pages-eighth-js" */),
  "component---src-pages-french-js": () => import("./../../../src/pages/french.js" /* webpackChunkName: "component---src-pages-french-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-languagearts-6-js": () => import("./../../../src/pages/languagearts6.js" /* webpackChunkName: "component---src-pages-languagearts-6-js" */),
  "component---src-pages-languagearts-7-js": () => import("./../../../src/pages/languagearts7.js" /* webpackChunkName: "component---src-pages-languagearts-7-js" */),
  "component---src-pages-languagearts-8-js": () => import("./../../../src/pages/languagearts8.js" /* webpackChunkName: "component---src-pages-languagearts-8-js" */),
  "component---src-pages-math-6-js": () => import("./../../../src/pages/math6.js" /* webpackChunkName: "component---src-pages-math-6-js" */),
  "component---src-pages-math-7-js": () => import("./../../../src/pages/math7.js" /* webpackChunkName: "component---src-pages-math-7-js" */),
  "component---src-pages-math-8-js": () => import("./../../../src/pages/math8.js" /* webpackChunkName: "component---src-pages-math-8-js" */),
  "component---src-pages-mobilemenu-js": () => import("./../../../src/pages/mobilemenu.js" /* webpackChunkName: "component---src-pages-mobilemenu-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-science-6-js": () => import("./../../../src/pages/science6.js" /* webpackChunkName: "component---src-pages-science-6-js" */),
  "component---src-pages-science-7-js": () => import("./../../../src/pages/science7.js" /* webpackChunkName: "component---src-pages-science-7-js" */),
  "component---src-pages-science-8-js": () => import("./../../../src/pages/science8.js" /* webpackChunkName: "component---src-pages-science-8-js" */),
  "component---src-pages-seventh-js": () => import("./../../../src/pages/seventh.js" /* webpackChunkName: "component---src-pages-seventh-js" */),
  "component---src-pages-sixth-js": () => import("./../../../src/pages/sixth.js" /* webpackChunkName: "component---src-pages-sixth-js" */),
  "component---src-pages-socialstudies-6-js": () => import("./../../../src/pages/socialstudies6.js" /* webpackChunkName: "component---src-pages-socialstudies-6-js" */),
  "component---src-pages-socialstudies-7-js": () => import("./../../../src/pages/socialstudies7.js" /* webpackChunkName: "component---src-pages-socialstudies-7-js" */),
  "component---src-pages-socialstudies-8-js": () => import("./../../../src/pages/socialstudies8.js" /* webpackChunkName: "component---src-pages-socialstudies-8-js" */),
  "component---src-pages-spanish-js": () => import("./../../../src/pages/spanish.js" /* webpackChunkName: "component---src-pages-spanish-js" */),
  "component---src-pages-termsofuse-js": () => import("./../../../src/pages/termsofuse.js" /* webpackChunkName: "component---src-pages-termsofuse-js" */),
  "component---src-pages-timer-20-js": () => import("./../../../src/pages/timer20.js" /* webpackChunkName: "component---src-pages-timer-20-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-subjects-js": () => import("./../../../src/templates/subjects.js" /* webpackChunkName: "component---src-templates-subjects-js" */)
}

